<script setup>

import { ref } from 'vue'
import { themeColorToHash } from '@/utils/convertThemeColorToHash.ts'

const loaded = ref(false)

onMounted(() => {
    loaded.value = true
})

const bibliaSk = useCookie("biblia_sk")

useHead({
    meta: [
        { name: 'theme-color', content: themeColorToHash(bibliaSk.value?.theme ? bibliaSk.value.theme : 'light') }
    ],
    htmlAttrs: {
        'data-theme': () => bibliaSk.value?.theme ? bibliaSk.value.theme : 'light',
    },
});

</script>
<template>
    <div>

        <Background :loaded="loaded"/>
        
        <Message/>

        <ClientOnly>
            <Donation/>
        </ClientOnly>

        <!--
        <ClientOnly>
            <NoticeToolbar/>
        </ClientOnly>
        -->
                
        <ClientOnly>
            <Header :loaded="loaded"/>
        </ClientOnly>

        <ClientOnly>
            <PodporaButton/>
        </ClientOnly>
        <slot />

    </div>
</template>